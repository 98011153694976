import '../../sass/components/_block_about-form.scss';

document.addEventListener('DOMContentLoaded', function() {
    let currentDateTimeField = document.getElementById('current-date-time');
    if (currentDateTimeField) {
        let now = new Date();
        let formattedDateTime = now.getFullYear() + '-' +
            ('0' + (now.getMonth() + 1)).slice(-2) + '-' +
            ('0' + now.getDate()).slice(-2) + ' ' +
            ('0' + now.getHours()).slice(-2) + ':' +
            ('0' + now.getMinutes()).slice(-2) + ':' +
            ('0' + now.getSeconds()).slice(-2);
        currentDateTimeField.value = formattedDateTime;
    }
});